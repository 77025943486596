<script>
import Vue from 'vue';
import moment from 'moment-timezone';

import SupplyManagementCustomKpiModal from './components/SupplyManagementCustomKpiModal.vue';
import SupplyManagementConnectPublishersModal from './components/SupplyManagementConnectPublishersModal.vue';
import SupplyManagementVisibleColumnsModal from './components/SupplyManagementVisibleColumnsModal.vue';
import SupplyManagementCapsTableSummaryCell from './components/SupplyManagementCapsTableSummaryCell.vue';
import SupplyManagementCapsTableCell from './components/SupplyManagementCapsTableCell.vue';
import { OfferKpi } from '../../../entities/OfferKpi';
import eventBus from '../../../lib/eventBus';
import { toDecimal } from '../../../lib/numbers';
import { copy, isEqual } from '../../../lib/objects';
import { pipe } from '../../../lib/functionalMethods';
import { getCr, getCvr, getPaRejectedPercentage, getRejectedPercentage } from '../../../lib/statsCalculations';
import Layout from "../../../components/Layout.vue";

/** @typedef {'dsp' | 'rewarded' | 'media_buying'} MediaType */

/**
 * @typedef PlacementStatusObject
 * @property {number} publisher_id
 * @property {string} publisher_name
 * @property {MediaType} publisher_media_type
 * @property {boolean} create_placement
 * @property {boolean} disabled
 * @property {number} auto_generated
 * @property {number} id
 * @property {number} publisher_status
 * @property {number} status
 */

/**
 * @typedef PlacementData
 * @property {number} daily_click_cap
 * @property {number} daily_conversion_cap
 * @property {number} daily_impression_cap
 * @property {number} daily_install_cap
 * @property {'active' | 'inactive'} effective_status
 * @property {number} id
 * @property {string} name
 * @property {number} paused_reason
 * @property {'CPI' | 'CPA'} payout_type
 * @property {number} publisher_status
 * @property {string} status
 */

/**
 * @typedef PublisherData
 * @property {number} id
 * @property {string} name
 */

/**
 * @typedef OfferData
 * @property {number} daily_click_cap
 * @property {number} daily_conversion_cap
 * @property {number} daily_impression_cap
 * @property {number} daily_install_cap
 * @property {number} daily_budget
 * @property {number} payout
 * @property {number} advertiser_status
 * @property {string} status
 * @property {number} paused_reason
 * @property {'active' | 'inactive'} effective_status
 */

/**
 * @typedef RawData
 * @property {PublisherData} publisher
 * @property {PlacementData} placement
 * @property {OfferData} offer
 * @property {number} passed_clicks
 * @property {number} passed_mmp_clicks
 * @property {number} blocked_clicks
 * @property {number} blocked_mmp_clicks
 * @property {number} passed_impressions
 * @property {number} blocked_impressions
 * @property {number} passed_mmp_impressions
 * @property {number} blocked_mmp_impressions
 * @property {number} installs
 * @property {number} mmp_installs
 * @property {number} conversions
 * @property {number} reject_events
 * @property {number} pa_rejections
 * @property {MediaType} media_type
 * @property {number} e1
 * @property {number} e2
 * @property {number} e3
 * @property {number} e4
 * @property {number} e5
 * @property {number} e6
 * @property {number} e7
 * @property {number} e8
 * @property {number} e9
 * @property {number} e10
 */

/**
 * @typedef {RawData & { 
 * reject_pct: number;
 * pa_rejections_pct: number;
 * cr: number;
 * mmp_cr: number;
 * cvr: number;
 * kpi: Record<string, number> }} Data
 */

/**
 * @typedef {Omit<Data, 'placement' | 'offer'> & { publisher: { media_type: MediaType } }} MediaTypeSummaryData
 */

/**
 * @typedef {Omit<MediaTypeSummaryData, 'publisher' | 'media_type'>} SummaryData
 */

/** @type {Record<string, {name: string; slug: string; visible: boolean}>} */
const defaultVisibleColumnsMap = {
  impressions: {
    name: 'Impressions',
    slug: 'impressions',
    visible: true,
  },
  blocked_impressions_percent: {
    name: '% Impressions Blocked',
    slug: 'blocked_impressions_percent',
    visible: true,
  },
  clicks: {
    name: 'Clicks',
    slug: 'clicks',
    visible: true,
  },
  blocked_clicks_percent: {
    name: '% Clicks Blocked',
    slug: 'blocked_clicks_percent',
    visible: true,
  },
  cr: {
    name: 'CR',
    slug: 'cr',
    visible: true,
  },
  installs: {
    name: 'Installs',
    slug: 'installs',
    visible: true,
  },
  cvr: {
    name: 'CVR',
    slug: 'cvr',
    visible: true,
  },
  conversions: {
    name: 'Conversions',
    slug: 'conversions',
    visible: true,
  },
  rt_rejections: {
    name: 'RT Rejections',
    slug: 'rt_rejections',
    visible: true,
  },
  pa_rejections: {
    name: 'PA Rejections',
    slug: 'pa_rejections',
    visible: true,
  },
}

/** @param {Record<string, any>} offer @returns {OfferData} */
function getDefaultOfferData(offer) {
  return {
    daily_click_cap: offer.daily_click_cap,
    daily_conversion_cap: offer.daily_conversion_cap,
    daily_impression_cap: offer.daily_impression_cap,
    daily_install_cap: offer.daily_install_cap,
    daily_budget: offer.daily_budget,
    payout: offer.payout,
    advertiser_status: offer.advertiser_status,
    status: offer.status,
    paused_reason: offer.paused_reason,
    effective_status: offer.effective_status,
  }
}
/**
 * @param {object} o
 * @param {OfferData} o.offer
 * @param {PublisherData} o.publisher
 * @returns {PlacementData}
 */
function getDefaultPlacementData({ offer, publisher }) {
  const randomId = -Math.floor(Math.random() * 100000)
  return {
    daily_click_cap: 0,
    daily_conversion_cap: 0,
    daily_impression_cap: 0,
    daily_install_cap: 0,
    effective_status: 'inactive',
    id: randomId,
    name: offer.name,
    paused_reason: 0,
    payout_type: 'CPI',
    publisher_status: publisher.status,
    status: 'live',
  }
}
/**
 * @param {PublisherData} publisher
 * @return {Omit<RawData, 'offer' | 'placement' | 'publisher' | 'kpi' | 'mediaType'>}
 */
function getDefaultStatsData(publisher) {
  return {
    blocked_clicks: 0,
    blocked_mmp_clicks: 0,
    conversions: 0,
    installs: 0,
    mmp_installs: 0,
    e1: 0,
    e2: 0,
    e3: 0,
    e4: 0,
    e5: 0,
    e6: 0,
    e7: 0,
    e8: 0,
    e9: 0,
    e10: 0,
    pa_rejections: 0,
    passed_clicks: 0,
    passed_mmp_clicks: 0,
    passed_impressions: 0,
    passed_mmp_impressions: 0,
    reject_events: 0,
    media_type: publisher.mediaType,
  }
}

/**
 * Calculates stats (cr, cvr, etc) and returns the record with the stats
 * @param {Data} record
 */
function calculateStatsAndAttach(record) {
  const {
    mmp_installs,
    passed_mmp_clicks,
  } = record;

  const cr = getCr(record);
  const cvr = getCvr(record);
  const reject_pct = getRejectedPercentage(record);
  const pa_rejections_pct = getPaRejectedPercentage(record);
  const mmpCr = getCr({ passed_clicks: passed_mmp_clicks, installs: mmp_installs });
  return {
    ...record,
    cr,
    cvr,
    reject_pct,
    pa_rejections_pct,
    mmp_cr: mmpCr,
  }
}
/**
 * Calculates KPIs and returns the record with the KPIs
 * @param {OfferKpi[]} kpis
 * @param {boolean} onlyMmpCr
 * @return {(record: Data) => Data} record
 */
const calculateKpisAndAttach = (kpis, onlyMmpCr) => (record) => {
  const copied = copy(record);
  const passedClicks = onlyMmpCr ? record.passed_mmp_clicks : record.passed_clicks;
  const installs = onlyMmpCr ? record.mmp_installs : record.installs;
  copied.kpi = {};
  for (const kpi of kpis) {
    const kpiValue = kpi ? kpi.calculateFormula({
      ...record,
      installs,
      passed_clicks: passedClicks
    }) : 0;
    const { slug } = kpi;
    copied.kpi[slug] = Number.isFinite(kpiValue) ? kpiValue : 0;
  }
  return copied;
}

/**
 * @param {number} offerId
 * @param {Record<string, any>} filters
 */
async function loadData(offerId, filters) {
  const response = await Vue.ovData.offer.getSupplyManagementData({
    startDate: moment(filters.datePicker.dateRange.startDate).startOf('day').format('YYYY-MM-DD HH:mm:00'),
    endDate: moment(filters.datePicker.dateRange.endDate).endOf('day').format('YYYY-MM-DD HH:mm:59'),
    isCohort: filters.isCohort,
    offerId: offerId,
  });
  return response;
}

/**
 * @param {number} offerId
 * @param {Record<string, any>} filters
 */
async function loadKpiData(offerId, filters) {
  const response = await Vue.ovData.offer.getSupplyManagementData({
    startDate: moment(filters.datePicker.dateRange.startDate).format('YYYY-MM-DD HH:mm:00'),
    endDate: moment(filters.datePicker.dateRange.endDate).format('YYYY-MM-DD HH:mm:00'),
    isCohort: filters.isCohort,
    offerId: offerId
  });
  return response.kpi.map((k) => new OfferKpi(k));
}

/**
 * @param {number} offerId
 */
async function loadPlacementStatus(offerId) {
  const response = await Vue.ovReq.get('placement/getOfferStatus/' + offerId);
  const placementStatusList = response.placement_status_list.map(p => ({
    ...p,
    create_placement: p.id !== null && p.status === 'live',
    disabled: !!p.auto_generated,
  }));
  return placementStatusList;
}

export default {
  name: 'SupplyManagement',
  components: {
    Layout,
    SupplyManagementCapsTableSummaryCell,
    SupplyManagementCapsTableCell,
  },
  async beforeRouteEnter(to, from, next) {
    const { id: offerId } = to.params;
    const offer = await Vue.ovData.offer.get(offerId, true);

    // Use a dummy filters object for initial load
    const dummyFilters = {
      datePicker: {
        dateRange: {
          startDate: moment().subtract(1, 'day').startOf('day').toDate(),
          endDate: moment().subtract(1, 'day').endOf('day').toDate(),
        },
      },
      isCohort: true,
    };

    const data = await loadData(offer.id, dummyFilters);
    const kpiData = await loadKpiData(offer.id, dummyFilters);
    const placementStatusList = await loadPlacementStatus(offer.id);
    const onlyMmpCr = offer.type === 'complex';

    const initialChangesMap = {}
    for (const d of data.data) {
      const placementId = d.placement.id;

      /** Fill in the initial changes map to compare against */
      const { daily_click_cap, daily_conversion_cap, daily_impression_cap, daily_install_cap, status } = d.placement
      initialChangesMap[placementId] = {
        status,
        daily_click_cap,
        daily_conversion_cap,
        daily_impression_cap,
        daily_install_cap,
      };
    }

    next(vm => {
      vm.offer = offer;
      vm.onlyMmpCr = onlyMmpCr;
      vm.rawData = data.data;
      vm.initialChangesMap = initialChangesMap;
      vm.kpi = kpiData;
      vm.placementStatusList = placementStatusList;
      vm.initialPlacementStatusList = JSON.parse(JSON.stringify(placementStatusList));
      vm.showImpressions = offer.impression_url || data.childrenHaveImpressions;
    });
  },
  async mounted() {
    eventBus.subscribe(eventBus.Events.SaveOfferKpi, (event) => this.saveKpi(event.detail));
    eventBus.subscribe(eventBus.Events.RemoveOfferKpi, (event) => this.removeKpi(event.detail));
    eventBus.subscribe(eventBus.Events.ConnectPublishersToOffer, (event) => this.setConnectedPublishers(event.detail));
    eventBus.subscribe(eventBus.Events.SaveVisibleColumns, (event) => this.saveVisibleColumns(event.detail));
  },
  beforeDestroy() {
    eventBus.unsubscribe(eventBus.Events.SaveOfferKpi, this.saveKpi);
    eventBus.unsubscribe(eventBus.Events.RemoveOfferKpi, this.removeKpi);
    eventBus.unsubscribe(eventBus.Events.ConnectPublishersToOffer, this.setConnectedPublishers);
    eventBus.unsubscribe(eventBus.Events.SaveVisibleColumns, this.saveVisibleColumns);
  },
  data() {
    const yesterday = moment().subtract(1, 'day');
    const visibleColumnsMapkey = `supplyManagementVisibleColumnsMap`;
    return {
      busy: false,
      offer: {},
      filters: {
        datePicker: {
          hideTZ: true,
          dateRange: {
            startDate: yesterday.startOf('day').toDate(),
            endDate: yesterday.endOf('day').toDate(),
          },
        },
        isCohort: true,
      },
      visibleColumnsMapkey,
      visibleColumnsMap: JSON.parse(localStorage.getItem(visibleColumnsMapkey)) || defaultVisibleColumnsMap,
      showImpressions: false,
      onlyMmpCr: false,
      /** @type {Data[]} */
      rawData: [],
      /** @type {OfferKpi[]} */
      kpi: [],
      /**
       * Used to track changes, holds each field's new value grouped by placement ID.
       * @type {Record<number, Pick<PlacementData, 'daily_click_cap' | 'daily_conversion_cap' | 'daily_impression_cap' | 'daily_install_cap' | 'status'>>}
       */
      changesMap: {},
      /**
       * Used to track changes, compare new cap with initial and statuses, so we'll know
       * if field was truly changed.
       * @type {Record<number, Pick<PlacementData, 'daily_click_cap' | 'daily_conversion_cap' | 'daily_impression_cap' | 'daily_install_cap' | 'status'>>}
       */
      initialChangesMap: {},
      isSaved: true,
      /** @type {PlacementStatusObject[]} */
      placementStatusList: [],
      /** @type {PlacementStatusObject[]} */
      initialPlacementStatusList: [],
    };
  },
  computed: {
    data() {
      /** @type {Data[]} */
      const data = this.rawData.map(pipe(
        calculateStatsAndAttach,
        calculateKpisAndAttach(this.kpi, this.onlyMmpCr)
      ));
      return data;
    },

    mediaTypeSummary() {
      /** @type {Record<MediaType, SummaryData>} */
      const data = this.data.reduce((acc, record) => {
        const {
          placement,
          conversions,
          installs,
          mmp_installs,
          reject_events,
          pa_rejections,
          media_type,
          passed_clicks,
          passed_mmp_clicks,
          passed_impressions,
          passed_mmp_impressions,
          blocked_clicks,
          blocked_mmp_clicks,
          blocked_impressions,
          blocked_mmp_impressions,
        } = record;
        const { status } = placement;
        if (status === 'paused') {
          return acc;
        }

        acc[media_type].passed_clicks += passed_clicks;
        acc[media_type].passed_mmp_clicks += passed_mmp_clicks;
        acc[media_type].blocked_clicks += blocked_clicks;
        acc[media_type].blocked_mmp_clicks += blocked_mmp_clicks;
        acc[media_type].passed_impressions += passed_impressions;
        acc[media_type].passed_mmp_impressions += passed_mmp_impressions;
        acc[media_type].blocked_impressions += blocked_impressions;
        acc[media_type].blocked_mmp_impressions += blocked_mmp_impressions;

        acc[media_type].conversions += conversions;
        acc[media_type].installs += installs;
        acc[media_type].mmp_installs += mmp_installs;
        acc[media_type].reject_events += reject_events;
        acc[media_type].pa_rejections += pa_rejections;
        for (let i = 1; i <= 10; i++) {
          acc[media_type][`e${i}`] += record[`e${i}`];
        }

        return acc;
      }, {
        dsp: { ...this.getSummaryDefaults('dsp') },
        rewarded: { ...this.getSummaryDefaults('rewarded') },
        media_buying: { ...this.getSummaryDefaults('media_buying') },
      });

      for (const [mediaType, mediaTypeData] of Object.entries(data)) {
        const cr = getCr(mediaTypeData);
        const cvr = getCvr(mediaTypeData);
        const mmpCr = getCr({
          passed_clicks: mediaTypeData.passed_mmp_clicks,
          installs: mediaTypeData.mmp_installs,
        });
        const rejectPct = getRejectedPercentage(mediaTypeData);
        const paRejectionsPct = getPaRejectedPercentage(mediaTypeData);
        const passedClicks = this.onlyMmpCr ? mediaTypeData.passed_mmp_clicks : mediaTypeData.passed_clicks;
        const installs = this.onlyMmpCr ? mediaTypeData.mmp_installs : mediaTypeData.installs;
        data[mediaType].cr = Number.isFinite(cr) ? cr : 0;
        data[mediaType].mmp_cr = Number.isFinite(mmpCr) ? mmpCr : 0;
        data[mediaType].cvr = Number.isFinite(cvr) ? cvr : 0;
        data[mediaType].reject_pct = Number.isFinite(rejectPct) ? rejectPct : 0;
        data[mediaType].pa_rejections_pct = Number.isFinite(paRejectionsPct) ? paRejectionsPct : 0;
        data[mediaType].kpi = {}
        for (const kpi of this.kpi) {
          const kpiValue = kpi ? kpi.calculateFormula({
            ...mediaTypeData,
            installs,
            passed_clicks: passedClicks,
          }) : 0;
          const { slug } = kpi;
          data[mediaType].kpi[slug] = Number.isFinite(kpiValue) ? kpiValue : 0;
        }
      }

      return data;
    },

    /** @type {() => SummaryData} */
    summary() {
      const data = this.getSummaryDefaults();

      for (const mediaTypeData of Object.values(this.mediaTypeSummary)) {
        const {
          installs,
          mmp_installs,
          conversions,
          reject_events,
          pa_rejections,
          passed_clicks,
          passed_mmp_clicks,
          passed_impressions,
          passed_mmp_impressions,
          blocked_clicks,
          blocked_mmp_clicks,
          blocked_impressions,
          blocked_mmp_impressions,
        } = mediaTypeData;
        data.installs += installs;
        data.mmp_installs += mmp_installs;
        data.conversions += conversions;
        data.reject_events += reject_events;
        data.pa_rejections += pa_rejections;
        data.passed_clicks += passed_clicks;
        data.passed_mmp_clicks += passed_mmp_clicks;
        data.blocked_clicks += blocked_clicks;
        data.blocked_mmp_clicks += blocked_mmp_clicks;
        data.passed_impressions += passed_impressions;
        data.passed_mmp_impressions += passed_mmp_impressions;
        data.blocked_impressions += blocked_impressions;
        data.blocked_mmp_impressions += blocked_mmp_impressions;

        for (let i = 1; i <= 10; i++) {
          data[`e${i}`] += mediaTypeData[`e${i}`];
        }
      }

      const cr = getCr(data);
      const cvr = getCvr(data);
      const mmpCr = getCr({
        passed_clicks: data.passed_mmp_clicks,
        installs: data.mmp_installs,
      });
      const rejectPct = getRejectedPercentage(data);
      const paRejectionsPct = getPaRejectedPercentage(data);
      const passedClicks = this.onlyMmpCr ? data.passed_mmp_clicks : data.passed_clicks;
      const installs = this.onlyMmpCr ? data.mmp_installs : data.installs;
      data.cr = Number.isFinite(cr) ? cr : 0;
      data.mmp_cr = Number.isFinite(mmpCr) ? mmpCr : 0;
      data.cvr = Number.isFinite(cvr) ? cvr : 0;
      data.reject_pct = Number.isFinite(rejectPct) ? rejectPct : 0;
      data.pa_rejections_pct = Number.isFinite(paRejectionsPct) ? paRejectionsPct : 0;

      data.kpi = {}
      for (const kpi of this.kpi) {
        const kpiValue = kpi ? kpi.calculateFormula({
          ...data,
          installs,
          passed_clicks: passedClicks,
        }) : 0;
        const { slug } = kpi;
        data.kpi[slug] = Number.isFinite(kpiValue) ? kpiValue : 0;
      }

      return data;
    },

    splitedData() {
      const data = {
        dsp: {
          /** @type {Data[]} */
          CPA: [],
          /** @type {Data[]} */
          CPI: [],
        },
        rewarded: {
          /** @type {Data[]} */
          CPA: [],
          /** @type {Data[]} */
          CPI: [],
        },
      };
      if (this.$store.state.user && this.$store.state.user.config.mediaBuyingSync) {
        data.media_buying = { CPA: [], CPI: [] };
      }
      for (const record of this.data) {
        if (!data[record.media_type]) {
          data[record.media_type] = {};
        }
        if (!data[record.media_type][record.placement.payout_type]) {
          data[record.media_type][record.placement.payout_type] = [];
        }
        data[record.media_type][record.placement.payout_type].push(record);
      }
      return data;
    },
    flattenedData() {
      const result = [];

      for (const [mediaType, mediaTypeData] of Object.entries(this.splitedData)) {
        let className = '';
        if (mediaTypeData.CPA.length === 0 && mediaTypeData.CPI.length === 0) {
          className = 'text-muted';
        }

        const summary = this.mediaTypeSummary[mediaType] || this.getSummaryDefaults(mediaType);

        result.push({
          key: `header-summary-${mediaType}`,
          value: summary,
          type: 'mediaType',
          className: className + ' media-type-row sticky-header top-third',
          mediaType,
          label: this.getMediaTypeLabel(mediaType)
        });

        for (const [payoutType, payoutTypeData] of Object.entries(mediaTypeData)) {
          if (payoutTypeData.length === 0) {
            continue;
          }

          result.push({
            key: `header-${mediaType}-${payoutType}`,
            value: payoutType,
            type: 'payoutType',
            className: '',
            mediaType,
          });

          for (const record of payoutTypeData) {
            if (record.placement.status === 'paused') {
              className = 'paused';
            }
            result.push({
              key: `record-${record.placement.id}`,
              value: record,
              type: 'data',
              className,
              mediaType,
            });
            className = '';
          }
        }
      }
      return result;
    },
    fullTableColspan() {
      let colspanWihoutkpi = 14;
      if (this.offer.impression_url) {
        colspanWihoutkpi += 2;
      }
      const disabledColumnsCount = Object.values(this.visibleColumnsMap).reduce((acc, c) => {
        if (c.visible) {
          return acc;
        }
        if (c.slug === 'installs' || c.slug === 'conversions') {
          return acc + 2;
        }
        return acc + 1;
      }, 0);
      return colspanWihoutkpi + this.kpi.length - disabledColumnsCount;
    },

    /** @returns {Record<number, { installs: number; conversions: number }>} */
    expectedValuesMap() {
      return this.data.reduce((acc, record) => {
        acc[record.placement.id] = {
          installs: this.calculateExpected(record, 'install'),
          conversions: this.calculateExpected(record, 'conversion'),
        };
        return acc;
      }, {});
    },
    expectedValuesMediaTypeSummaryMap() {
      /** @type {Record<'dsp' | 'rewarded' | 'media_buying', { installs: number; conversions: number }>} */
      const expectedValues = {};

      for (const placementId in this.expectedValuesMap) {
        const record = this.data.find(r => r.placement.id === Number(placementId));

        if (record.placement.status === 'paused') {
          continue;
        }

        const mediaType = record.media_type;
        if (!expectedValues[mediaType]) {
          expectedValues[mediaType] = {
            installs: 0,
            conversions: 0,
          };
        }
        expectedValues[mediaType].installs += this.expectedValuesMap[placementId].installs;
        expectedValues[mediaType].conversions += this.expectedValuesMap[placementId].conversions;
      }

      return expectedValues;
    },
    /** @returns {{ installs: number; conversions: number }} */
    expectedValuesSummaryMap() {
      const expectedValues = {
        installs: 0,
        conversions: 0,
      };
      for (const mediaType in this.expectedValuesMediaTypeSummaryMap) {
        expectedValues.installs += this.expectedValuesMediaTypeSummaryMap[mediaType].installs;
        expectedValues.conversions += this.expectedValuesMediaTypeSummaryMap[mediaType].conversions;
      }
      return expectedValues;
    },

    targetValuesMediaTypeSummaryMap() {
      /** @type {Record<'dsp' | 'rewarded' | 'media_buying', { installs: number; conversions: number; passed_clicks: number; passed_impressions: number }>} */
      const targetValues = {};

      for (const record of this.data) {
        const { placement, media_type: mediaType } = record;

        if (placement.status === 'paused') {
          continue;
        }

        if (!targetValues[mediaType]) {
          targetValues[mediaType] = {
            installs: 0,
            conversions: 0,
            passed_clicks: 0,
            passed_impressions: 0,
          };
        }
        targetValues[mediaType].installs += placement.daily_install_cap || 0;
        targetValues[mediaType].conversions += placement.daily_conversion_cap || 0;
        targetValues[mediaType].passed_clicks += placement.daily_click_cap || 0;
        targetValues[mediaType].passed_impressions += placement.daily_impression_cap || 0;
      }

      for (const mediaType in targetValues) {
        if (this.offer.daily_install_cap) {
          targetValues[mediaType].installs = this.offer.daily_install_cap;
        }
        if (this.offer.daily_conversion_cap) {
          targetValues[mediaType].conversions = this.offer.daily_conversion_cap;
        }
        if (this.offer.daily_click_cap) {
          targetValues[mediaType].passed_clicks = this.offer.daily_click_cap;
        }
        if (this.offer.daily_impression_cap) {
          targetValues[mediaType].passed_impressions = this.offer.daily_impression_cap;
        }
      }

      return targetValues;
    },
    targetValuesSummaryMap() {
      const targetValues = {
        installs: 0,
        conversions: 0,
        passed_clicks: 0,
        passed_impressions: 0,
      };
      for (const mediaType in this.targetValuesMediaTypeSummaryMap) {
        targetValues.installs += this.targetValuesMediaTypeSummaryMap[mediaType].installs;
        targetValues.conversions += this.targetValuesMediaTypeSummaryMap[mediaType].conversions;
        targetValues.passed_clicks += this.targetValuesMediaTypeSummaryMap[mediaType].passed_clicks;
        targetValues.passed_impressions += this.targetValuesMediaTypeSummaryMap[mediaType].passed_impressions;
      }
      return targetValues;
    },

    daysBetweenDates() {
      const { startDate, endDate } = this.filters.datePicker.dateRange;
      return moment(endDate).diff(moment(startDate), 'days');
    },

    kpiNames() {
      return this.kpi.map((k) => k.name);
    },

    hasChanges() {
      let changesIsDifferentFromInitial = false;
      for (const [placementId, changes] of Object.entries(this.changesMap)) {
        const initialCaps = this.initialChangesMap[parseInt(placementId)];
        for (const [field, value] of Object.entries(changes)) {
          if (value === initialCaps[field]) {
            continue;
          }
          changesIsDifferentFromInitial = true;
          break;
        }

        if (changesIsDifferentFromInitial) {
          break;
        }
      }

      const placementStatusDiffers = !isEqual(this.placementStatusList, this.initialPlacementStatusList);

      return placementStatusDiffers || changesIsDifferentFromInitial;
    },
  },
  methods: {
    async loadData() {
      if (this.busy) {
        return;
      }
      this.busy = true;
      try {
        console.log(this.offer)
        const response = await loadData(this.offer.id, this.filters);
        this.showImpressions = this.offer.impression_url || response.childrenHaveImpressions;
        this.kpi = response.kpi.map((k) => new OfferKpi(k));
        this.rawData = response.data;

        for (const d of this.rawData) {
          const placementId = d.placement.id;

          /** Fill in the initial changes map to compare against */
          const { daily_click_cap, daily_conversion_cap, daily_impression_cap, daily_install_cap, status } = d.placement
          this.initialChangesMap[placementId] = {
            status,
            daily_click_cap,
            daily_conversion_cap,
            daily_impression_cap,
            daily_install_cap,
          };

          const placementChanges = this.changesMap[placementId];
          if (!placementChanges) {
            continue;
          }

          /** Assign the changes to the placement */
          for (const f in placementChanges) {
            d.placement[f] = placementChanges[f];
          }
        }
      } catch (e) {
        console.log(e);
      }
      this.busy = false;
    },
    async loadKpiData() {
      if (this.busy) {
        return;
      }
      this.busy = true;
      try {
        const response = await loadKpiData(this.offer.id, this.filters);

        /** Assign new KPIs */
        this.kpi = response.kpi.map((k) => new OfferKpi(k));
      } catch (e) {
        console.log(e);
      }
      this.busy = false;
    },
    async loadPlacementStatus() {
      this.placementStatusList = await loadPlacementStatus(this.offer.id);
      this.initialPlacementStatusList = copy(this.placementStatusList);
    },
    async saveChanges() {
      if (!this.hasChanges || this.busy) return;
      const confirmed = await this.$bvModal.msgBoxConfirm(
        'Are you sure you want to save changes?', { centered: true }
      );
      if (!confirmed) return;

      const data = {
        changes: this.changesMap,
        kpi: this.kpi,
        offerId: this.offer.id,
        connectedPublishers: this.placementStatusList
          .filter((p) => p.create_placement)
          .map((p) => {
            const dataElement = this.rawData.find((d) => d.publisher.id === p.publisher_id);
            if (!dataElement) {
              throw new Error('Data element not found, not possible');
            }

            return {
              id: p.publisher_id,
              daily_click_cap: dataElement.placement.daily_click_cap,
              daily_conversion_cap: dataElement.placement.daily_conversion_cap,
              daily_impression_cap: dataElement.placement.daily_impression_cap,
              daily_install_cap: dataElement.placement.daily_install_cap,
            }
          }),
      };
      this.busy = true;

      try {
        await Vue.ovData.offer.saveSupplyManagementData(data);
        this.$emit('report-saved-changes', this.hasChanges);
        this.$ovNotify.success('Changes saved successfully');
        this.changesMap = {};
      } catch (e) {
        console.log(e);
      }
      this.busy = false;
      await Promise.all([this.loadData(), this.loadPlacementStatus()]);
    },
    async resetChanges() {
      const confirmed = await this.$bvModal.msgBoxConfirm(
        'Are you sure you want to reset all changes?', { centered: true }
      );
      if (!confirmed) return;

      this.changesMap = {};
      this.$emit('report-saved-changes', this.hasChanges);
      this.$ovNotify.success('Changes reset successfully');

      await Promise.all([this.loadData(), this.loadPlacementStatus()]);
    },

    /**
     * NOT USED RIGHT NOW, BUT MAY BE USED IN THE FUTURE
     * @deprecated
     * @param {Data | MediaTypeSummaryData | SummaryData} record
     */
    getAverageForDays(record) {
      const { passed_clicks: passed_clicks, conversions, passed_impressions, installs, cr, cvr,
        pa_rejections, pa_rejections_pct, reject_events, reject_pct, kpi } = record;
      const daysCount = this.daysBetweenDates + 1;

      /** @param {number} field */
      const toAverage = (field) => field / daysCount;

      /** @type {typeof kpi} */
      const newKpi = {};
      for (const key in kpi) {
        newKpi[key] = toAverage(kpi[key]).toFixed(2);
      }

      /** @type {Data | MediaTypeSummaryData | SummaryData} */
      const newRecord = {
        ...record,
        passed_clicks: Math.ceil(toAverage(passed_clicks)),
        conversions: Math.ceil(toAverage(conversions)),
        passed_impressions: Math.ceil(toAverage(passed_impressions)),
        installs: Math.ceil(toAverage(installs)),
        cr: parseFloat(toAverage(cr).toFixed(2)),
        cvr: parseFloat(toAverage(cvr).toFixed(2)),
        pa_rejections: Math.ceil(toAverage(pa_rejections)),
        pa_rejections_pct: parseFloat(toAverage(pa_rejections_pct).toFixed(2)),
        reject_events: Math.ceil(toAverage(reject_events)),
        reject_pct: parseFloat(toAverage(reject_pct).toFixed(2)),
        kpi: newKpi,
      };
      return newRecord;
    },


    openVisibleColumnsModal() {
      this.$modal.show(
        SupplyManagementVisibleColumnsModal,
        {
          columnsMap: this.visibleColumnsMap,
        },
        {
          height: 'auto',
          width: '600px',
          scrollable: true,
        },
      );
    },
    /** @param {{name: string, visible: boolean}[]} columnsMap */
    async saveVisibleColumns(columnsMap) {
      this.visibleColumnsMap = columnsMap;
      localStorage.setItem(this.visibleColumnsMapkey, JSON.stringify(columnsMap));
    },

    /** @param {'dsp' | 'rewarded' | 'media_buying'} mediaType */
    openConnectPublishersModal(mediaType) {
      this.$modal.show(
        SupplyManagementConnectPublishersModal,
        {
          mediaType,
          placementStatusList: this.placementStatusList
            .sort((a, b) => a.publisher_name.localeCompare(b.publisher_name))
        },
        {
          height: 'auto',
          scrollable: true,
        }
      );
    },
    /** @param {number[]} connectedPublisherIds */
    setConnectedPublishers(connectedPublisherIds) {
      /** @type {number[]} */
      const disconnectedPublisherIds = [];
      /** @type {{ id: number; name: string; status: number; mediaType: MediaType }[]} */
      const newlyConnectedPublishers = [];
      this.placementStatusList = this.placementStatusList.map((p) => {
        if (connectedPublisherIds.includes(p.publisher_id)) {
          p.create_placement = true;

          const dataElement = this.rawData.find((d) => d.publisher.id === p.publisher_id);
          if (!dataElement) {
            /** If this publisher wasn't connected before, add it to the list */
            newlyConnectedPublishers.push({
              id: p.publisher_id,
              name: p.publisher_name,
              status: p.publisher_status,
              mediaType: p.publisher_media_type,
            });
          } else {
            const placement = dataElement.placement;
            this.updateField(placement, { status: 'live' });
          }
        } else {
          p.create_placement = false;
          disconnectedPublisherIds.push(p.publisher_id)
        }

        return p;
      });

      /** Find disconnected publishers and pause placements */
      for (const publisherId of disconnectedPublisherIds) {
        const element = this.rawData.find((d) => d.publisher.id === publisherId)
        if (!element) {
          continue;
        }
        Vue.set(element.placement, 'status', 'paused');
      }

      /** Add new placements into data array */
      for (const publisherData of newlyConnectedPublishers) {
        const newDataElement = this.getNewDataElement(publisherData)
        this.initialChangesMap[newDataElement.placement.id] = {
          status: newDataElement.placement.status,
          daily_click_cap: newDataElement.placement.daily_click_cap,
          daily_conversion_cap: newDataElement.placement.daily_conversion_cap,
          daily_impression_cap: newDataElement.placement.daily_impression_cap,
          daily_install_cap: newDataElement.placement.daily_install_cap,
        };
        this.rawData.push(newDataElement);
      }

      this.$emit('report-saved-changes', this.hasChanges);
    },

    /** @param {PublisherData & { status: number; mediaType: MediaType }} publisher */
    getNewDataElement(publisher) {
      const offerData = getDefaultOfferData(this.offer);
      const placementData = getDefaultPlacementData({ offer: this.offer, publisher });

      /** @type {Record<string, number>} */
      const kpi = {}
      for (const k of this.kpi) {
        kpi[k.slug] = 0;
      }

      /** @type {Data} */
      const data = {
        offer: offerData,
        placement: placementData,
        publisher,
        kpi,
        ...getDefaultStatsData(publisher),
      };

      return data;
    },

    /** @param {OfferKpi} [kpi] */
    openKpiModal(kpi) {
      this.$modal.show(
        SupplyManagementCustomKpiModal,
        {
          kpi,
          offer: this.offer,
          existingNames: this.kpiNames,
        },
        {
          height: 'auto',
          width: '300px',
          scrollable: true,
        },
      );
    },
    /** @param {OfferKpi} kpiData */
    async saveKpi(kpiData) {
      if (kpiData.id) {
        this.kpi.find((k) => k.slug === kpiData.slug).update(kpiData);
      } else {
        const kpi = new OfferKpi({
          ...kpiData,
          offer_id: this.offer.id,
        });
        this.kpi.push(kpi);
      }
      await this.saveKpiChanges();
    },
    /** @param {OfferKpi} kpi */
    async removeKpi(kpi) {
      const { slug } = kpi;
      const kpiIndex = this.kpi.findIndex((k) => k.slug === slug);
      if (kpiIndex === -1) return;
      this.kpi.splice(kpiIndex, 1);
      await this.saveKpiChanges();
    },
    async saveKpiChanges() {
      const data = {
        kpi: this.kpi,
        offerId: this.offer.id,
        kpiOnly: true,
      };
      await Vue.ovData.offer.saveSupplyManagementData(data);
      this.$ovNotify.success('KPI has been saved');
      await this.loadKpiData();
    },

    /**
     * @param {PlacementData} placement
     * @param {string} capName
     * @param {number} capValue
     */
    setNewCap(placement, capName, capValue) {
      this.updateField(placement, { [capName]: capValue || 0 })
    },
    /** @param {'dsp' | 'rewarded' | 'media_buying'} mediaType */
    getMediaTypeLabel(mediaType) {
      if (mediaType === 'dsp') return 'DSP'
      if (mediaType === 'rewarded') return 'Rewarded'
      if (mediaType === 'media_buying') return 'Media Buying'
    },
    /** @param {PlacementData} placement @param {'live' | 'paused'} status */
    setPlacementStatus(placement, status) {
      this.updateField(placement, { status });
      const dataElement = this.rawData.find((r) => r.placement.id === placement.id);
      if (!dataElement) {
        return;
      }

      const { publisher } = dataElement;
      this.placementStatusList = this.placementStatusList.map((p) => {
        if (p.publisher_id === publisher.id) {
          p.create_placement = status === 'live';
        }
        return p;
      });
    },
    /**
     * @param {PlacementData} placement
     * @param {Record<string, any>} changes
     */
    updateField(placement, changes) {
      const placementId = placement.id;
      const newChangesMap = copy(this.changesMap);
      newChangesMap[placementId] = { ...newChangesMap[placementId], ...changes };
      this.changesMap = newChangesMap;
      this.rawData = this.rawData.map(record => {
        if (record.placement.id === placementId) {
          const updatedPlacement = { ...record.placement, ...changes };
          return { ...record, placement: updatedPlacement };
        }
        return record;
      });
      this.$emit('report-saved-changes', this.hasChanges);
    },

    /** @param {'dsp' | 'rewarded' | 'media_buying'} [mediaType] */
    getSummaryDefaults(mediaType) {
      const kpi = this.kpi.reduce((acc, k) => {
        acc[k.slug] = 0;
        return acc;
      }, {});
      /** @type {SummaryData} */
      const data = {
        passed_impressions: 0,
        passed_mmp_impressions: 0,
        blocked_impressions: 0,
        blocked_mmp_impressions: 0,
        passed_clicks: 0,
        passed_mmp_clicks: 0,
        blocked_clicks: 0,
        blocked_mmp_clicks: 0,
        cr: 0,
        installs: 0,
        mmp_installs: 0,
        cvr: 0,
        conversions: 0,
        reject_events: 0,
        reject_pct: 0,
        pa_rejections: 0,
        pa_rejections_pct: 0,
        kpi,
      }
      for (let i = 1; i <= 10; i++) {
        data[`e${i}`] = 0;
      }
      if (mediaType) {
        data.publisher = { media_type: mediaType };
      }
      return data;
    },
    /** @param {number} value @param {number} total */
    calculatePercentage(value, total) {
      return total ? (value / total) * 100 : 0;
    },
    /**
     * @param {Data} value
     * @param {'install' | 'conversion'} field
     */
    calculateExpected(value, field) {
      const { offer, placement, cr, cvr, installs, conversions } = value;
      const placementClickCap = placement.daily_click_cap;
      const offerClickCap = offer.daily_click_cap;
      const placementInstallCap = placement.daily_install_cap;
      const offerInstallCap = offer.daily_install_cap;
      const placementConversionCap = placement.daily_conversion_cap;
      const offerConversionCap = offer.daily_conversion_cap;


      const expectedInstalls = Math.ceil((placementClickCap || offerClickCap) * cr / 100);
      const expectedMinInstalls = Math.floor(
        Math.min(expectedInstalls, placementInstallCap || Infinity, offerInstallCap || Infinity)
      );

      switch (field) {
        case 'install': {
          if (expectedMinInstalls === 0) return installs;
          return expectedMinInstalls;
        }
        case 'conversion': {
          const offerBudgetPossibleConversions = offer.daily_budget / offer.payout;
          const expected = Math.ceil(expectedMinInstalls * cvr / 100);
          const expectedMinConversions = Math.min(
            expected,
            placementConversionCap || Infinity,
            offerConversionCap || Infinity,
            offerBudgetPossibleConversions || Infinity
          )
          if (expectedMinConversions === 0) return conversions;
          return expectedMinConversions;
        }
        default:
          throw new Error(`Unexpected field: ${field}`);
      }
    },

    /**
     * @param {Data} dataValue
     * @param {'install' | 'conversion'} field
     */
    getExpectedValue(dataValue, field) {
      return this.expectedValuesMap[dataValue.placement.id][field];
    },
    /**
     * @param {Data} dataValue
     * @param {'daily_click_cap' | 'daily_conversion_cap' | 'daily_impression_cap' | 'daily_install_cap'} field
     */
    getInitialCapValue(dataValue, field) {
      if (!this.initialChangesMap[dataValue.placement.id]) return 0
      return this.initialChangesMap[dataValue.placement.id][field] || 0;
    },
    /**
     * @param {'dsp' | 'rewarded'} mediaType
     * @param {'installs' | 'conversions'} field
     */
    getMediaTypeSummaryExpectedValue(mediaType, field) {
      if (!this.expectedValuesMediaTypeSummaryMap[mediaType]) return 0
      return this.expectedValuesMediaTypeSummaryMap[mediaType][field];
    },
    /** @param {'installs' | 'conversions'} field */
    getSummaryExpectedValue(field) {
      return this.expectedValuesSummaryMap[field];
    },

    /**
     * @param {'dsp' | 'rewarded'} mediaType
     * @param {'installs' | 'conversions' | 'passed_clicks'} field
     */
    getMediaTypeSummaryTargetValue(mediaType, field) {
      if (!this.targetValuesMediaTypeSummaryMap[mediaType]) return 0
      return this.targetValuesMediaTypeSummaryMap[mediaType][field];
    },
    /** @param {'installs' | 'conversions' | 'passed_clicks'} field */
    getSummaryTargetValue(field) {
      return this.targetValuesSummaryMap[field];
    },

    /**
     * @param {Data} value
     * @param {string} kpiSlug 
     */
    getKpiValue(value, kpiSlug) {
      const valueKpi = value.kpi[kpiSlug];
      if (valueKpi === undefined) {
        return 'No data';
      }
      return valueKpi;
    },

    /**
     * @param {Data} value
     */
    getCrValue(value) {
      const cr = this.onlyMmpCr ? value.mmp_cr : value.cr;
      return this.toDecimal(cr);
    },

    /** @param {Data} value @param {'clicks' | 'impressions'} metric */
    getBlockedPercentage(value, metric) {
      const {
        passed_clicks,
        passed_mmp_clicks,
        passed_impressions,
        passed_mmp_impressions,
        blocked_clicks,
        blocked_impressions,
        blocked_mmp_clicks,
        blocked_mmp_impressions
      } = value;
      const blockedClicks = this.onlyMmpCr ? blocked_mmp_clicks : blocked_clicks;
      const blockedImpressions = this.onlyMmpCr ? blocked_mmp_impressions : blocked_impressions;
      const passedClicks = this.onlyMmpCr ? passed_mmp_clicks : passed_clicks;
      const passedImpressions = this.onlyMmpCr ? passed_mmp_impressions : passed_impressions;
      const totalClicks = passedClicks + blockedClicks;
      const totalImpressions = passedImpressions + blockedImpressions;

      if (metric === 'impressions') {
        return blockedImpressions / totalImpressions;
      }
      return blockedClicks / totalClicks * 100;
    },

    /**
     * @param {number} value
     */
    percentage(value) {
      if (Number.isNaN(value)) return '0%';
      return `${this.toDecimal(value)}%`
    },

    /**
     * @param {number} value
     */
    shortNumber(value) {
      return Vue.filter('shortNumber')(value);
    },

    /**
     * @param {number} value
     */
    toDecimal(value) {
      const isDecimal = value.toString().includes('.');
      if (isDecimal) {
        return value >= 1 ? toDecimal(value) : toDecimal(value, 4);
      }
      return value;
    },

    /**
     * @param {Data} record
     * @param {'daily_click_cap' | 'daily_conversion_cap' | 'daily_impression_cap' | 'daily_install_cap'} key
     */
    isChanged(record, key) {
      const placementId = record.placement.id;
      const placementChanges = this.changesMap[placementId];
      if (!placementChanges) return false;
      const initialValue = this.initialChangesMap[placementId][key]
      const value = placementChanges[key];
      return value !== undefined && value !== initialValue;
    },

    /** @param {Data} record */
    shouldShowRecord(record) {
      const { placement } = record;
      const placementChanges = this.changesMap[placement.id];
      if (!placementChanges) {
        return placement.status === 'live';
      }

      if (placement.status !== 'live' && placementChanges.status === 'live') {
        return true;
      }

      if (placementChanges.status === 'live') {
        return true;
      }

      return true;
    },

    /** @param {string} columnName */
    shouldShowColumn(columnName) {
      if (!this.visibleColumnsMap[columnName]) {
        return true;
      }
      return this.visibleColumnsMap[columnName].visible;
    },

    /**
     * @param {PlacementData} placementData
     */
    getPlacementReportPageQuery(placementData) {
      const query = {
        placement_id: placementData.id,
      };
      if (this.offer.type === 'complex') {
        query.complex_offer_id = this.offer.id;
      }
      if (this.offer.type === 'normal') {
        query.offer_id = this.offer.id;
      }
      return query;
    }
  }
};
</script>

<template lang="pug">
.widget
  loading(:active.sync="busy", :is-full-page="true")
  .widget-header
    h1.title {{ offer.name }}
    .d-flex.justify-content-end.gap-2.align-items-center
      button.btn.btn-danger(v-if="hasChanges", type="button" @click="resetChanges", v-b-tooltip.hover.bottom, title="Discard Supply Changes") Reset
        i.la.la-fw.la-redo-alt
      //p.m-0(v-if="hasChanges") You have unsaved changes
      button.btn.btn-success(type="button" @click="saveChanges" :disabled="!hasChanges || busy", v-b-tooltip.hover.bottom, title="Apply Supply Changes") Save
        i.la.la-fw.la-save
  .widget-body
    .d-flex.justify-content-between
      form.form.form-inline.ov-filters(@submit.prevent="loadData()", class="expanded")
        .form-row-main
          .main-filters-row
            .main-filters
              ov-date-time-picker.date-range-picker(v-model="filters.datePicker", :single-date-only="true")
              .form-group.no-label
                b-form-checkbox(v-model="filters.isCohort") Cohort
              | &nbsp;
              .form-group.no-label(style="margin-left: auto;")
                button.btn.btn-secondary(type="button", @click="openKpiModal()") Add KPI
              .form-group.no-label
                button.btn.btn-primary(type="button", @click="loadData(); loadPlacementStatus()", :disabled="busy") Go
      .d-flex.align-items-center
        router-link.btn.btn-action-info(
          :to="{ name:'reports', query: offer.type==='complex' ? { complex_offer_id: offer.id } : { offer_id: offer.id }}",
          v-b-tooltip.hover.bottom,
          title="Statistics"
          target="_blank"
        )
          i.la.la-2x.la-bar-chart
        i.la.la-2x.la-cog.clickable(@click="openVisibleColumnsModal")


    .data
      .ov-table-wrapper.sticky-table
        table.table.table-bordered
          thead
            tr.sticky-header.main-header
              th(rowspan="2") Publisher
              th(rowspan="2") Placement
              th(rowspan="2", style="width: 60px") Status
              th(rowspan="2", v-if="showImpressions && shouldShowColumn('impressions')") Impressions
              th(rowspan="2", v-if="showImpressions && shouldShowColumn('blocked_impressions_percent')") % Impressions Blocked
              th(rowspan="2", v-if="shouldShowColumn('clicks')") Clicks
              th(rowspan="2", v-if="shouldShowColumn('blocked_clicks_percent')") % Clicks Blocked
              th(rowspan="2", v-if="shouldShowColumn('cr')")
                .d-flex.justify-content-between.align-items-center
                  span CR
                  b-checkbox(
                    v-if="offer.type === 'complex'", 
                    switch="",
                    v-model="onlyMmpCr",
                    v-b-tooltip.hover,
                    title="Calculate CR only from MMP offers")
              th(colspan="2", v-if="shouldShowColumn('installs')") Installs
              th(rowspan="2", v-if="shouldShowColumn('cvr')") CVR
              th(colspan="2", v-if="shouldShowColumn('conversions')") Conversions
              th(rowspan="2", v-for="k in kpi")
                span {{ k.name }}
                span
                  i.la.la-fw.la-pencil.clickable(@click="openKpiModal(k)")
              th(rowspan="2", v-if="shouldShowColumn('rt_rejections')") RT Rejections
              th(rowspan="2", v-if="shouldShowColumn('pa_rejections')") PA Rejections
              th(rowspan="2", style="width: 55px") Actions
            tr.sticky-header.main-header.top-half-step
              th(v-if="shouldShowColumn('installs')") Caps
              th(v-if="shouldShowColumn('installs')") Expected

              th(v-if="shouldShowColumn('conversions')") Caps
              th(v-if="shouldShowColumn('conversions')") Expected

          tbody
            tr.total-summary-row.sticky-header.top-second
              th(colspan="3")
                b Total
              th(v-if="showImpressions && shouldShowColumn('impressions')")
                .d-flex.gap-1
                  SupplyManagementCapsTableSummaryCell(
                    :target="getSummaryTargetValue('passed_impressions')",
                    :actual="summary.passed_impressions")
              th(v-if="showImpressions && shouldShowColumn('blocked_impressions_percent')")
                input.table-input.form-control.font-weight-bold(:value="`${percentage(getBlockedPercentage(summary, 'impressions'))}`", readonly)
              th(v-if="shouldShowColumn('clicks')")
                .d-flex.gap-1
                  SupplyManagementCapsTableSummaryCell(
                    :target="getSummaryTargetValue('passed_clicks')",
                    :actual="summary.passed_clicks")
              th(v-if="shouldShowColumn('blocked_clicks_percent')")
                input.table-input.form-control.font-weight-bold(:value="`${percentage(getBlockedPercentage(summary, 'clicks'))}`", readonly)
              th(v-if="shouldShowColumn('cr')")
                input.table-input.form-control.font-weight-bold(:value="percentage(getCrValue(summary))", readonly)
              th(colspan="2", v-if="shouldShowColumn('installs')")
                .d-flex.gap-1
                  SupplyManagementCapsTableSummaryCell(
                    :target="getSummaryTargetValue('installs')",
                    :actual="summary.installs",
                    :expected="getSummaryExpectedValue('installs')")
              th(v-if="shouldShowColumn('cvr')")
                input.table-input.p-0.form-control.font-weight-bold(:value="percentage(summary.cvr)", readonly)
              th(colspan="2", v-if="shouldShowColumn('conversions')")
                .d-flex.gap-1
                  SupplyManagementCapsTableSummaryCell(
                    :target="getSummaryTargetValue('conversions')",
                    :actual="summary.conversions",
                    :expected="getSummaryExpectedValue('conversions')")
              th(v-for="k in kpi")
                input.table-input.p-0.form-control.font-weight-bold(:value="`${getKpiValue(summary, k.slug)}${k.sign}`", readonly)
              th(v-if="shouldShowColumn('rt_rejections')")
                input.table-input.p-0.form-control.font-weight-bold(:value="`${summary.reject_events} (${summary.reject_pct ? toDecimal(summary.reject_pct) : 0 }%)`", readonly)
              th(v-if="shouldShowColumn('pa_rejections')")
                input.table-input.p-0.form-control.font-weight-bold(:value="`${summary.pa_rejections} (${summary.pa_rejections_pct ? toDecimal(summary.pa_rejections_pct) : 0 }%)`", readonly)
              th

            tr(v-for="{ value, key, type, label, className, mediaType } in flattenedData", :key="key", :class="className")
              template(v-if="type === 'mediaType'")
                th(colspan="3")
                  .d-flex.justify-content-between.align-items-center
                    h2.media-type-header.m-0 {{ label }}
                    h2.media-type-header.m-0(v-b-tooltip.hover, :title="`${label} Installs`") {{ toDecimal(calculatePercentage(value.installs, summary.installs)) }}%
                th(v-if="showImpressions && shouldShowColumn('impressions')")
                  .d-flex.gap-1
                    SupplyManagementCapsTableSummaryCell(
                      :target="getMediaTypeSummaryTargetValue(mediaType, 'passed_impressions')",
                      :actual="value.passed_impressions")
                th(v-if="showImpressions && shouldShowColumn('blocked_impressions_percent')")
                  input.table-input.form-control.font-weight-bold(:value="`${percentage(getBlockedPercentage(value, 'impressions'))}`", readonly)
                th(v-if="shouldShowColumn('clicks')")
                  .d-flex.gap-1
                    SupplyManagementCapsTableSummaryCell(
                      :target="getMediaTypeSummaryTargetValue(mediaType, 'passed_clicks')",
                      :actual="value.passed_clicks")
                th(v-if="shouldShowColumn('blocked_clicks_percent')")
                  input.table-input.form-control.font-weight-bold(:value="`${percentage(getBlockedPercentage(value, 'clicks'))}`", readonly)
                th(v-if="shouldShowColumn('cr')")
                  input.table-input.form-control.font-weight-bold(:value="`${percentage(getCrValue(value))}`", readonly)
                th(colspan="2", v-if="shouldShowColumn('installs')")
                  .d-flex.gap-1
                    SupplyManagementCapsTableSummaryCell(
                      :target="getMediaTypeSummaryTargetValue(mediaType, 'installs')",
                      :actual="value.installs",
                      :expected="getMediaTypeSummaryExpectedValue(mediaType, 'installs')")
                th(v-if="shouldShowColumn('cvr')")
                  input.table-input.p-0.form-control.font-weight-bold(:value="`${percentage(value.cvr)}`", readonly)
                th(colspan="2", v-if="shouldShowColumn('conversions')")
                  .d-flex.gap-1
                    SupplyManagementCapsTableSummaryCell(
                      :target="getMediaTypeSummaryTargetValue(mediaType, 'conversions')",
                      :actual="value.conversions",
                      :expected="getMediaTypeSummaryExpectedValue(mediaType, 'conversions')")
                th(v-for="k in kpi")
                  input.table-input.p-0.form-control.font-weight-bold(:value="`${getKpiValue(value, k.slug)}${k.sign}`", readonly)
                th(v-if="shouldShowColumn('rt_rejections')")
                  input.table-input.p-0.form-control.font-weight-bold(:value="`${value.reject_events} (${toDecimal(value.reject_pct)})%`", readonly)
                th(v-if="shouldShowColumn('pa_rejections')")
                  input.table-input.p-0.form-control.font-weight-bold(:value="`${value.pa_rejections} (${toDecimal(value.pa_rejections_pct)})%`", readonly)
                th
                  i.la.la-2x.la-plus-circle.clickable(@click="openConnectPublishersModal(mediaType)", v-b-tooltip.hover.bottom, :title="`Add ${label} Publishers`")


              td(v-if="type === 'payoutType'", :colspan="fullTableColspan")
                h3.payout-type-header.m-0(v-if="type === 'payoutType'") {{ value === 'null' ? 'Payout type not set' : value }}

              template(v-if="type === 'data' && shouldShowRecord(value)")
                td
                  entity(:id="value.publisher.id", :name="value.publisher.name", type="publisher")
                td
                  entity(v-if="value.placement.id > 0", :id="value.placement.id", :name="value.placement.name", type="placement")
                td
                  offer-paused-reason(:offer="value.offer", :placement="value.placement")
                td(v-if="showImpressions && shouldShowColumn('impressions')", :class="{ changed: isChanged(value, 'daily_impression_cap') }")
                  SupplyManagementCapsTableCell(
                    :actual="value.passed_impressions"
                    :initial="getInitialCapValue(value, 'daily_impression_cap')"
                    :target="value.placement.daily_impression_cap"
                    @on-save-target="(newCap) => setNewCap(value.placement, 'daily_impression_cap', newCap)"
                  )
                th(v-if="showImpressions && shouldShowColumn('blocked_impressions_percent')")
                  input.table-input.form-control(:value="`${percentage(getBlockedPercentage(value, 'impressions'))}`", readonly)
                td(:class="{ changed: isChanged(value, 'daily_click_cap') }", v-if="shouldShowColumn('clicks')")
                  SupplyManagementCapsTableCell(
                    :actual="value.passed_clicks"
                    :initial="getInitialCapValue(value, 'daily_click_cap')"
                    :target="value.placement.daily_click_cap"
                    @on-save-target="(newCap) => setNewCap(value.placement, 'daily_click_cap', newCap)"
                  )
                th(v-if="shouldShowColumn('blocked_clicks_percent')")
                  input.table-input.form-control(:value="`${percentage(getBlockedPercentage(value, 'clicks'))}`", readonly)
                td(v-if="shouldShowColumn('cr')")
                  input.table-input.form-control(:value="`${percentage(getCrValue(value))}`", readonly)
                td(colspan="2", v-if="shouldShowColumn('installs')", :class="{ changed: isChanged(value, 'daily_install_cap') }")
                  SupplyManagementCapsTableCell(
                    :actual="value.installs"
                    :expected="getExpectedValue(value, 'installs')"
                    :initial="getInitialCapValue(value, 'daily_install_cap')"
                    :target="value.placement.daily_install_cap"
                    @on-save-target="(newCap) => setNewCap(value.placement, 'daily_install_cap', newCap)"
                  )
                td(v-if="shouldShowColumn('cvr')")
                  input.table-input.p-0.form-control(:value="`${percentage(value.cvr)}`", readonly)
                td(colspan="2", v-if="shouldShowColumn('conversions')", :class="{ changed: isChanged(value, 'daily_conversion_cap') }")
                  SupplyManagementCapsTableCell(
                    :actual="value.conversions"
                    :expected="getExpectedValue(value, 'conversions')"
                    :initial="getInitialCapValue(value, 'daily_conversion_cap')"
                    :target="value.placement.daily_conversion_cap"
                    @on-save-target="(newCap) => setNewCap(value.placement, 'daily_conversion_cap', newCap)"
                  )
                td(v-for="k in kpi")
                  input.table-input.p-0.form-control(:value="`${toDecimal(getKpiValue(value, k.slug))}${k.sign}`", readonly)
                td(v-if="shouldShowColumn('rt_rejections')")
                  input.table-input.p-0.form-control(:value="`${value.reject_events} (${toDecimal(value.reject_pct)})%`", readonly)
                td(v-if="shouldShowColumn('pa_rejections')")
                  input.table-input.p-0.form-control(:value="`${value.pa_rejections} (${toDecimal(value.pa_rejections_pct)})%`", readonly)
                td
                  .d-flex.align-items-center.gap-2
                    i.la.la-2x.la-play-circle.clickable(v-if="value.placement.status !== 'live'" @click="setPlacementStatus(value.placement, 'live')", v-b-tooltip.hover.bottom, title="Resume")
                    i.la.la-2x.la-pause-circle.clickable(v-if="value.placement.status === 'live'" @click="setPlacementStatus(value.placement, 'paused')", v-b-tooltip.hover.bottom, title="Pause")
                    router-link.btn.btn-action-info.p-0(
                      :to="{ name:'reports', query: getPlacementReportPageQuery(value.placement) }",
                      v-b-tooltip.hover.bottom,
                      title="Statistics"
                      target="_blank"
                    )
                      i.la.la-lg.la-bar-chart

            tr(v-if="!flattenedData.length")
              td(:colspan="fullTableColspan") No data available for selected period
</template>

<style lang="scss" scoped>
.data {
  overflow-x: scroll;
}

table {
  // table-layout: fixed;
}

th {
  min-width: 80px;
}

td {
  padding: 7px;

  input {
    padding: 0;
  }
}

.table-input {
  flex: 1;
  text-align: center;
  padding: 0;
}

.table-input:read-only {
  background-color: transparent;
}

.total-summary-row {
  background-color: antiquewhite;

  th {
    background-color: antiquewhite;
  }
}

.media-type-row {
  background-color: aliceblue;

  th {
    background-color: aliceblue;
  }
}

.main-header {
  height: 30px;

  th {
    background-color: white;
  }
}

.media-type-header {
  font-size: 20px;
  font-weight: bold;
}

.payout-type-header {
  font-size: 16px;
  font-weight: bold;
}

.empty {
  background-color: #9999;
}

.paused {
  background-color: rgba(224, 10, 10, .1);
}

.hidden {
  display: none;
}

.changed {
  background-color: #ffc10733;
}

.top-half-step {
  th {
    top: 30px !important;
  }
}

.top-second {
  th {
    top: 58px !important;
  }
}

.top-third {
  th {
    top: 110px !important;
  }
}
</style>
