<script>
import Vue from 'vue';
import eventBus from '../../../../lib/eventBus';

export default {
  name: 'SupplyManagementCustomKpiModal',
  props: {
    offer: {
      type: Object,
      required: true,
    },
    kpi: {
      type: Object,
      required: false,
      default: null,
    },
    existingNames: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  mounted() {
    if (this.kpi) {
      this.form.name = this.kpi.name;
      this.form.metric_base = this.kpi.metric_base;
      this.form.metric_target = this.kpi.metric_target;
      // this.form.comparator = this.kpi.comparator;
      // this.form.value = this.kpi.value;
    }
  },
  data() {
    return {
      form: {
        name: '',
        metric_base: null,
        metric_target: null,
        // comparator: null,
        // value: 0,
      },
    }
  },
  computed: {
    kpiRulesOptions() {
      return {
        metrics: Vue.ovData.rule.metricOptions(
          this.offer.events.map((e, idx) => ({
            v: 'e' + (idx + 1),
            t: `e${idx + 1}: ${e.name}`
          }))
        ),
        comparator: Vue.ovData.rule.rangeOptions,
      }
    },
    formula() {
      /** @type {Record<string, string>} */
      const metricNamesByValueMap = {};
      for (const { v, t } of this.kpiRulesOptions.metrics) {
        metricNamesByValueMap[v] = t;
      }
      const { metric_base, metric_target } = this.form;
      const baseMetricName = metricNamesByValueMap[metric_base];
      if (!metric_target) return baseMetricName;
      const targetMetricName = metricNamesByValueMap[metric_target];
      return `${baseMetricName} / ${targetMetricName}`;
    }
  },
  methods: {
    validate() {
      const { metric_base, name, /* value, comparator */ } = this.form;
      // alphanumeric, underscore, space
      const nameRegex = /^[a-zA-Z0-9_ ]+$/;
      if (!nameRegex.test(name)) {
        this.$ovNotify.error('Name can only contain alphanumeric characters, underscore and space');
        return false;
      }
      if (!metric_base || /* !comparator || !value || */ !name) {
        // this.$ovNotify.error('Required fields: Name, Metric 1, Comparator, Ratio (%)');
        this.$ovNotify.error('Required fields: Name, Metric 1');
        return false;
      }
      if (this.existingNames.includes(name) && !this.kpi.id) {
        this.$ovNotify.error('This KPI name already exists');
        return false;
      }
      return true;
    },

    save() {
      if (!this.validate()) {
        return;
      }
      const data = { ...this.kpi, ...this.form };
      eventBus.send(eventBus.Events.SaveOfferKpi, data);
      this.$emit('close');
    },

    remove() {
      eventBus.send(eventBus.Events.RemoveOfferKpi, this.kpi);
      this.$emit('close');
    },
  },
};
</script>

<template lang="pug">
div.default-modal
  .modal-header
    .modal-title {{ kpi !== null ? 'Edit' : 'Add' }} Custom KPI 
    a.close(href="javascript:void(0);", @click="$emit('close')")
      i.la.la-times

  .modal-body
    .modal-body-inner
      p Add a column to the table to help you keep track of your KPI
      form(@submit.prevent="save")
        .form-group
          label.control-label Name
          input.form-control(type="text", placeholder="Name", v-model="form.name")
        .form-group
          label.control-label Metric 1
          select.form-control(v-model="form.metric_base")
            option(v-for="o in kpiRulesOptions.metrics", :value="o.v") {{ o.t }}
        .form-group
          label.control-label Metric 2
          select.form-control(v-model="form.metric_target")
            option(v-for="o in [{ v: null, t: '' }, ...kpiRulesOptions.metrics]", :value="o.v") {{ o.t }}
        //- .form-group
        //-   label.control-label Comparator
        //-   select.form-control(v-model="form.comparator")
        //-     option(v-for="o in kpiRulesOptions.comparator", :value="o.v") {{ o.t }}
        //- .form-group
        //-   label.control-label Ratio (%)
        //-   input.form-control(type="number", placeholder="0", v-model="form.value")
        .form-group
          label.control-label Formula
          h6.text-muted {{ formula }}
        .form-group.d-flex.justify-content-end.gap-1.m-0
          button.btn.btn-danger(v-if="kpi", type="button", @click="remove") Delete
          button.btn.btn-primary(type="submit") Save
</template>
