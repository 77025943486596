<script>
import Vue from 'vue';

export default {
  name: 'SupplyManagementCapsTableCell',
  props: {
    target: { type: Number, required: true },
    actual: { type: Number, required: true },
    expected: { type: Number },
    initial: { type: Number, required: true },
  },
  computed: {
    visibleActual() {
      return Vue.filter('shortNumber')(this.actual);
    },
    visibleTarget: {
      get() {
        return Vue.filter('numberCommas')(this.numberTarget);
      },
      set(newValue) {
        const parsedValue = parseInt(newValue.replace(/,/g, ''), 10);
        if (!isNaN(parsedValue)) {
          this.numberTarget = parsedValue;
        } else {
          this.numberTarget = 0;
        }
      },
    },
    visibleExpected() {
      return Vue.filter('shortNumber')(this.expected);
    },
    progress() {
      if (this.target === 0) {
        return 0;
      }
      return Math.min((this.actual / this.target) * 100, 100);
    },
    showProgressBar() {
      return {
        background: `linear-gradient(to right, #ccffcc ${this.progress}%, #f2f2f2 ${this.progress}%)`
      };
    },
    couldBeReseted() {
      return this.initial !== this.target;
    },
  },
  data() {
    return {
      isEditing: false,
      isHovered: false,
      numberTarget: this.target,
    };
  },
  methods: {
    async saveTarget() {
      this.isEditing = false;
      this.$emit('on-save-target', this.numberTarget);
    },
    async reset() {
      this.isEditing = false;
      this.$emit('on-save-target', parseInt(this.initial));
    }
  },
  watch: {
    isEditing(newValue) {
      if (!newValue) {
        return;
      }
      this.$nextTick(() => {
        this.$refs.targetInput.focus();
      });
    },
    target(newValue) {
      this.numberTarget = newValue;
    },
  },
};
</script>

<template lang="pug">
.d-flex.gap-1
  .position-relative.cell-input(v-if="!isEditing && !isHovered")
    input.cell-input.p-0.form-control.text-center(
      @click="isEditing = true",
      @mouseenter="isHovered = true",
      :value="visibleActual", 
      :style="showProgressBar", 
      :class="{'text-muted': visibleActual === 0}"
    )
    i.la.la-xl.la-times.clickable.reset-icon(v-if="couldBeReseted", @click="reset")

  .position-relative.cell-input(v-if="isEditing || isHovered")
    input.p-0.form-control.text-center(
      @blur="saveTarget",
      @click="isEditing = true",
      @mouseleave="isHovered = false",
      v-model="visibleTarget",
      class="editing",
      ref="targetInput"
    )
    i.la.la-xl.la-times.clickable.reset-icon(v-if="couldBeReseted", @click="reset")

  input.cell-input.p-0.form-control(
    v-show="expected !== undefined",
    :value="visibleExpected",
    readonly,
    :class="{'text-muted': visibleExpected === 0}"
  )
</template>

<style lang="scss" scoped>
.cell-input {
  text-align: center;
  flex: 1;
}

.reset-icon {
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: small;
}

.cell-input:read-only {
  background-color: transparent;
}
</style>