const Events = {
  SaveOfferKpi: 'saveOfferKpi',
  RemoveOfferKpi: 'removeOfferKpi',
  ConnectPublishersToOffer: 'connectPublishersToOffer',
  SaveVisibleColumns: 'saveVisibleColumns',
};

const eventBus = {
  /**
   * @param {string} eventName Event name to send
   * @param {any} data Data to send
   */
  send(eventName, data) {
    window.dispatchEvent(new CustomEvent(eventName, { detail: data }));
  },
  /**
   * @param {string} eventName Event name to listen to
   * @param {EventListenerOrEventListenerObject} callback Callback to do something when event is received
   */
  subscribe(eventName, callback) {
    window.addEventListener(eventName, callback);
  },
  /**
   * @param {string} eventName Event name to unsubscribe from
   * @param {EventListenerOrEventListenerObject} callback Callback to do something when event is received
   */
  unsubscribe(eventName, callback) {
    window.removeEventListener(eventName, callback);
  },

  Events,
};
export default eventBus;