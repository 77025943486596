<script>
import { copy } from '../../../../lib/objects';
import eventBus from '../../../../lib/eventBus';

export default {
  name: 'SupplyManagementVisibleColumnsModal',
  props: {
    columnsMap: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      copy: copy(this.columnsMap),
    };
  },
  methods: {
    save() {
      eventBus.send(eventBus.Events.SaveVisibleColumns, this.copy);
      this.$emit('close');
    },
  },
};
</script>

<template lang="pug">
div.default-modal
  .modal-header
    .modal-title Visible columns
    a.close(href="javascript:void(0);", @click="$emit('close')")
      i.la.la-times

  .modal-body
    .modal-body-inner
      .row
        .col-sm-4(v-for="c in copy", :class="{'text-muted':!c.visible}")
          b-form-checkbox(v-model="c.visible") {{ c.name }}
      .form-group.d-flex.justify-content-end.gap-1.m-0
        button.btn.btn-primary(@click="save") Save
</template>
